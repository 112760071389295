import { Injectable } from '@angular/core';
import { LoginModel } from './login/login-page/login.model';
import { AuthDataService } from '../core/data/auth/auth-data.service';
import { Observable } from 'rxjs';
import { share, tap } from 'rxjs/operators';
import { ForgotModel } from './login/forgot-page/forgot.model';
import { ChangeModel } from './login/change-page/change.model';
import { environment } from 'environments/environment';

@Injectable()
export class AuthService {

    private accessTokenCookieName = 'access-token-'+ environment.ENVIROMENT_NAME;

    constructor(private authDataService: AuthDataService) {

        const accessToken = this.authDataService.getDomainCookie(this.accessTokenCookieName);

        if (accessToken && accessToken.length > 0) {
            this.setLoggedIn(true);
        }
    }
    public redirectUrl = '';
    public isLoggedIn = false;
    private setLoggedIn(value: boolean) {
        this.isLoggedIn = value;
        if (value) {
            window.document.body.classList.add('logged-in');
        } else {
            window.document.body.classList.remove('logged-in');
        }
    }

    public login(loginModel: LoginModel): Observable<string> {
        return this.authDataService.login(loginModel.password, loginModel.username).pipe(tap((result) => {
            this.authDataService.setDomainCookie(this.accessTokenCookieName, result);
            this.setLoggedIn(true);
        }), share());
    }

    public forgot(forgotModel: ForgotModel): Observable<boolean> {
        return this.authDataService.forgot(forgotModel.email).pipe(tap((result) => {
            // comment
        }), share());
    }

    public change(changeModel: ChangeModel): Observable<boolean> {
        return this.authDataService.change(changeModel.token, changeModel.password, changeModel.passwordConfirm).pipe(tap((result) => {
            // comment
        }), share());
    }

    public logout(): Observable<boolean> {
        return this.authDataService.logout().pipe(tap((result) => {
            this.authDataService.setDomainCookie(this.accessTokenCookieName, '', -1);
            // this.setLoggedIn(false);
            window.location.reload();
        }), share());
    }
}
