import { Injectable } from '@angular/core';
import { ILookupable } from '../base-listable-data.service';
import { ILookup } from '../lookup.model';
import { Observable } from 'rxjs';
import { SpotAppType, TemplateInstanceProductGroupFetchingRule } from '../enums/enums';

// fake service for lookup
@Injectable()
export class TemplateInstanceProductGroupFetchingRuleDataService implements ILookupable<ILookup> {
    public lookupItems$: Observable<ILookup[]>;

    constructor() {
        const lookups: ILookup[] = [];

        lookups.push({ id: TemplateInstanceProductGroupFetchingRule.NoGroups, name: 'No Groups' });
        lookups.push({ id: TemplateInstanceProductGroupFetchingRule.PoolingGroups, name: 'Pooling Groups' });
        lookups.push({ id: TemplateInstanceProductGroupFetchingRule.AllGroups, name: 'All Groups' });

        this.lookupItems$ = new Observable<ILookup[]>((observer) => {
            observer.next(lookups);
            observer.complete();
        });
    }
}
