import { BaseIdModel, IBaseIdModel } from '../base-id.model';
import * as Enumerable from 'linq-es2015';
import * as moment from 'moment';

import { ObjectType } from '../enums/enums';
import { ISystemLanguage, SystemLanguage } from '../language/system-language.model';
import { PropertySet } from './property-set.model';
import { ILookup } from '../lookup.model';

export interface IPropertyValue extends IBaseIdModel {
    language_id: number;
    // property_id: number;
    content: string | boolean | number[];
    content_override: string | boolean | number[];
    content_type_id: ObjectType;
    content_data: any[];
    content_override_data: any[];
    is_overrided: boolean;
    language: ISystemLanguage; // filled later in UI code
    // object_id: number;
}

export class PropertyValue extends BaseIdModel<IPropertyValue> {
    constructor(public propertySet: PropertySet = null, dtm: IPropertyValue = null) {
        super(dtm || {
            id: 0,
            client_id: null,
            language_id: null,
            language: null,
            // property_id: 0,
            content: null,
            content_override: null,
            content_type_id: ObjectType.UNKNOWN,
            content_data: null,
            content_override_data: null,
            is_overrided: false,
            // object_id: null,

            // created_at: null,
            // updated_at: null,
            // created_by: null,
            // updated_by: null
        });
        this.update(this.dtm, true, true);
    }

    private dateFormat = 'YYYY-MM-DD[T]HH:mm:ss';

    public get language_id() {
        return this.dtm.language_id;
    }
    public set language_id(value) {
        this.dtm.language_id = value;
    }
    
    // public get property_id() {
    //     return this.dtm.property_id;
    // }
    // public set property_id(value) {
    //     this.dtm.property_id = value;
    // }

    public get contentString() {
        return this.dtm.content as string;
    }
    public set contentString(value) {
        this.dtm.content = value;
    }
    public get contentOverrideString() {
        return this.dtm.content_override as string;
    }
    public set contentOverrideString(value) {
        this.dtm.content_override = value;
    }
    public get contentIDs() {
        return this.dtm.content as number[];
    }
    public set contentIDs(value) {
        this.dtm.content = value;
    }
    public get contentOverrideIDs() {
        return this.dtm.content_override as number[];
    }
    public set contentOverrideIDs(value) {
        this.dtm.content_override = value;
    }
    public get firstContentID() {
        if (this.dtm.content && (this.dtm.content as number[]).length) {
            return this.dtm.content[0];
        }
        return null;
    }
    public get firstContentOverrideID() {
        if (this.dtm.content_override && (this.dtm.content_override as number[]).length) {
            return this.dtm.content_override[0];
        }
        return null;
    }
    public get contentBoolean() {
        return this.dtm.content as boolean;
    }
    public set contentBoolean(value) {
        this.dtm.content = value;
    }
    public get contentOverrideBoolean() {
        return this.dtm.content_override as boolean;
    }
    public set contentOverrideBoolean(value) {
        this.dtm.content_override = value;
    }
    public get contentDateTime() {
        let value = this.contentString;
        if (value) {
            if (value.indexOf('+') > 0) {
                value = value.split('+')[0];
            }
            value = moment(value).format(this.dateFormat);
        }
        return value;
    }
    public set contentDateTime(value) {
        if (value) {
            value = moment(value).format(this.dateFormat);
        }
        this.contentString = value;
    }
    public get contentOverrideDateTime() {
        let value = this.contentOverrideString;
        if (value) {
            if (value.indexOf('+') > 0) {
                value = value.split('+')[0];
            }
            value = moment(value).format(this.dateFormat);
        }
        return value;
    }
    public set contentOverrideDateTime(value) {
        if (value) {
            value = moment(value).format(this.dateFormat);
        }
        this.contentOverrideString = value;
    }
    public get firstContentDataName() {
        if (this.dtm.content_data && this.dtm.content_data.length && this.dtm.content_data[0].name) {
            return this.dtm.content_data[0].name;
        }
        return this.contentString;
    }
    public get firstContentOverrideDataName() {
        if (this.dtm.content_override_data && this.dtm.content_override_data.length && this.dtm.content_override_data[0].name) {
            return this.dtm.content_override_data[0].name;
        }
        return this.contentOverrideString;
    }
    public get languageName() {
        if (this.language) {
            return this.language.name;
        }
        return 'Unknown';
    }

    public get languageCode() {
        if (this.language) {
            return this.language.code;
        }
        return 'unknown';
    }


    private _contentObjects: ILookup[];
    public get contentObjects() {
        if (!this._contentObjects && this.contentIDs && this.contentIDs.length) {
            this._contentObjects = [];
            this.contentIDs.forEach((x) => {
                var name = 'ID: ' + x;
                if(this.dtm.content_data && this.dtm.content_data.length) {
                    var match = this.dtm.content_data.find((
                        (data) => data.id === x
                    ));
                    if(match && match.name && match.name.length) {
                        name = match.name;
                    }
                }
                this._contentObjects.push({
                    id: x,
                    name: name
                });
            });
        }
        return /*stange*/ this._contentObjects;
    }
    public set contentObjects(contents) {

        if (contents && !Array.isArray(contents)) {
            contents = [contents];
        }

        this._contentObjects = contents;
       
        if(contents) {
            this.contentIDs = contents.map((x) => x.id);
        } else {
            this.contentIDs = null;
        }
    }

    private _contentOverrideObjects: ILookup[];
    public get contentOverrideObjects() {
        if (!this._contentOverrideObjects && this.contentOverrideIDs && this.contentOverrideIDs.length) {
            this._contentOverrideObjects = [];
            this.contentOverrideIDs.forEach((x) => {
                var name = 'ID: ' + x;
                if(this.dtm.content_override_data && this.dtm.content_override_data.length) {
                    var match = this.dtm.content_override_data.find((
                        (data) => data.id === x
                    ));
                    if(match && match.name && match.name.length) {
                        name = match.name;
                    }
                }
                this._contentOverrideObjects.push({
                    id: x,
                    name: name
                });
            });
        }
        return /*stange*/ this._contentOverrideObjects;
    }
    public set contentOverrideObjects(contents) {

        if (contents && !Array.isArray(contents)) {
            contents = [contents];
        }

        this._contentOverrideObjects = contents;
       
        if(contents) {
            this.contentOverrideIDs = contents.map((x) => x.id);
        } else {
            this.contentOverrideIDs = null;
        }
    }

    public get isOverrided() {
        return this.dtm.is_overrided;
    }
    public set isOverrided(value) {
        this.dtm.is_overrided = value;
    }

    public /*item*/ language: SystemLanguage;

    public update(dtm: IPropertyValue, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);

        if (dtm.language) {
            if (this.language) {
                this.language.update(dtm.language, forceReplaceDtm);
            } else {
                this.language = new SystemLanguage(dtm.language);
            }
        } else {
            this.language = null;
        }
    }

    public cleanForSave(dtm: IPropertyValue, saver: ObjectType) {
        super.cleanForSave(dtm, saver);

    }
}
