import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IListData } from '../base-data.service';
import { BaseListableDataService } from '../base-listable-data.service';
import { ILookup, ILookupWithTitles } from '../lookup.model';
import { ToasterService } from '../../services/toaster.service';
import { Observable } from 'rxjs';
import { catchError, map, share, tap } from 'rxjs/operators';

import { Campaign, ICampaign } from './campaign.model';
import { SortMy } from '../sort/sort.model';
import { FilterMy } from '../filter/filter.model';
import * as Enumerable from 'linq-es2015';
import { CampaignType, ObjectType } from '../enums/enums';

@Injectable()
export class CampaignDataService extends BaseListableDataService<Campaign, ICampaign, ILookupWithTitles> {

  constructor(http: HttpClient, toastr: ToasterService) {
    super(http, toastr, 'campaign', ObjectType.CAMPAIGN,
      ['related'], ['related', 'media', 'rating', 'events', 'locations', 'screengroups', 'campaignSchedules', 'spotAppSources']);

      this.requiredfilters = [new FilterMy({key: 'type_id', value: CampaignType.Generic })];
  }

  protected getLookupDatas(
    pageNr: number, pageSize: number,
    filters: FilterMy[], sorts: SortMy[], includes: string[],
    search = '', query = '', subResourceName = ''): Observable<IListData<ILookupWithTitles>> {

    sorts = [new SortMy({ key: 'created_at', direction: 'desc' })];

    return this.getPage(pageNr, pageSize, filters, sorts, search, query, subResourceName, includes).pipe(map((res) => {
      return {
        data: Enumerable.AsEnumerable(res.data).Select((x) => {
          const campaign = new Campaign(x);
          const titles = campaign.titleProperty ? Enumerable.AsEnumerable(campaign.titleProperty.values)
            .Where((v) => (!v.isOverrided && !!v.contentString && v.contentString !== x.name) || (v.isOverrided && !!v.contentOverrideString && v.contentOverrideString !== x.name))
            .Select((v) =>  v.isOverrided ? v.contentOverrideString : v.contentString).Distinct().ToArray() : [];
          return {
            id: x.id,
            name: x.name,
            titles
          };
        }).ToArray(),
        filter: res.filter,
        totalCount: res.totalCount,
      };
    }));
  }
}
