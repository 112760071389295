import { environment } from 'environments/environment';
import * as Enumerable from 'linq-es2015';
import { Subject } from 'rxjs';
import { share } from 'rxjs/operators';
import { PropertyUpdateMode } from '../base.model';
import { ChannelBase, IChannelBase } from '../channel/channel.base.model';
import { DeviceDeviceRelationType, DeviceRotation, DeviceType, ObjectType, Status, StatusSystem, FileType, TransitionMode, TransitionDirection, 
    PlaylistCalculationFrequency, ApectRatioRestrictionMode, PlayerScheme, PlayerTimeMatchMode } from '../enums/enums';
import { ILocationMy } from '../location/location.model';
import { ILookup, IScreenGroupLookup } from '../lookup.model';
import { ScreenGroup } from '../screen-group/screen-group.model';
import { ITimeRangeRelation, TimeRangeRelation } from '../time-range/time-range-relation.model';
import { DeviceAuditoriumRelation, IDeviceAuditoriumRelation } from './device-auditorium-relation.model';
import { DeviceChannelRelation, IDeviceChannelRelation } from './device-channel-relation';
import { DeviceDeviceRelation, IDeviceDeviceRelation } from './device-device-relation.model';
import { DeviceExtContentSourceRelation, IDeviceExtContentSourceRelation } from './device-ext-content-source-relation';
import { DeviceScreenGroupRelation, IDeviceScreenGroupRelation } from './device-screengroup-relation.model';
import { DeviceBase, IDeviceBase } from './device.base.model';
import { DeviceSalesPointRelation, IDeviceSalesPointRelation } from './device-salespoint-relation.model';
import { DeviceTagRelation, IDeviceTagRelation } from './device-tag-relation.model';
import { ITag } from '../tag/tag.model';
import { IAuditorium } from '../auditorium/auditorium.model';


const defaultWebPlayerVersion = "V3";

export interface IDevicePlayerOptionSet {
    useVideoHack?: DeviceRotation;
    useTexture?: boolean;
    allowPlaylistFallback?: boolean;
    hideBackground?: boolean;
    webPlayerHeight?: number;
    webPlayerWidth?: number;
    webPlayerTop?: number;
    webPlayerLeft?: number;
    hackCode?: string;
    cacheBustAdd?: string;
    renderOptions?: string;
    useInputInBackground?: boolean;
    debugMode?: number;
    playerScheme?: PlayerScheme;
    mediaCacheUrl?: string;
    transitionMode?: TransitionMode;
    transitionDirection?: TransitionDirection;
    environmentName?: string;
    webPlayerVersion?: string;
    instanceBackgroundColor?: string;
    onloadPlayContentFromStart?: boolean;
    enableSound?: boolean;
    enableTimeSync?: boolean;
    reloadInterval?: number;
    timeMatchMode?: PlayerTimeMatchMode;
}

export interface IDevicePlaylistOptionSet {
    useFileType?: FileType;
    calculationFrequency?: PlaylistCalculationFrequency;
    calculationLength?: number;
    apect_ratio_restriction_mode?: ApectRatioRestrictionMode;
}

export interface IDeviceOptionSet {
    mediaCacheUrl?: string;
    player_option_set?: IDevicePlayerOptionSet;
    playlist_option_set?: IDevicePlaylistOptionSet;
}

export interface IDevice extends IDeviceBase {

    hash: string;
    description: string;
    width: number;
    height: number;

    option_set: string;
    option_set_fake: IDeviceOptionSet; // TODO: remove when option_set in not string

    location_id: number;
    location: ILocationMy;

    rotation_physical: number;
    rotation_os: number;
    rotation_container: number;
    rotation_accept_media_video: number;
    transpose_accept_media_video: number;

    ext_id: string;
    type_id: DeviceType;
    ipv4: string;
    mac_wired: string;
    mac_wireless: string;

    deviceChannels: IDeviceChannelRelation[];
    screengroupDevices: IDeviceScreenGroupRelation[];
    deviceDevices: IDeviceDeviceRelation[];
    extContentSourceDevices: IDeviceExtContentSourceRelation[];
    deviceAuditoriums: IDeviceAuditoriumRelation[];
    timerangeRelations: ITimeRangeRelation[];

    salespointDevices: IDeviceSalesPointRelation[];
    deviceTags: IDeviceTagRelation[];
}

export class Device extends DeviceBase<IDevice> {
    constructor(dtm: IDevice = null) {
        super(dtm || {
            id: 0,
            client_id: 0,
            hash: '',
            name: '',
            description: '',
            width: 0,
            height: 0,
            option_set: '',
            option_set_fake: {
                mediaCacheUrl: null,
                player_option_set: null,
                playlist_option_set: null
            },

            location_id: 0,
            location: null,
            rotation_physical: 0,
            rotation_os: 0,
            rotation_container: 0,
            rotation_accept_media_video: 0,
            transpose_accept_media_video: 0,

            ext_id: null,
            type_id: 0,
            ipv4: null,
            mac_wired: null,
            mac_wireless: null,

            deviceChannels: [],
            screengroupDevices: [],
            deviceDevices: [],
            extContentSourceDevices: [],
            deviceAuditoriums: [],
            timerangeRelations: [],
            salespointDevices: [],
            deviceTags: [],

            status: Status.Active,
            status_system: StatusSystem.Default,

            created_at: null,
            updated_at: null,
            created_by: null,
            updated_by: null

        });
        this.update(this.dtm, true, true);
    }


    public get display_name() {
        return this.name;
    }

    public get hash() {
        return this.dtm.hash;
    }
    public set hash(value) {
        this.dtm.hash = value;
    }

    public get hashUrl() {
        return `${environment.VOOGSTV_FRONT_URL}/${this.dtm.hash}`;
    }

    public get description() {
        return this.dtm.description;
    }
    public set description(value) {
        this.dtm.description = value;
    }

    public get width() {
        return this.dtm.width;
    }
    public set width(value) {
        this.dtm.width = value;
    }

    public get height() {
        return this.dtm.height;
    }
    public set height(value) {
        this.dtm.height = value;
    }

    public get size() {
        if (this.width || this.height) {
            return `${this.width ? this.width : '0'}x${this.height ? this.height : '0'}`;
        }
        return null;
    }

    public get rotations() {
        return `${this.rotation_physical}|${this.rotation_os}|${this.rotation_container}`;
    }

    public get videoTranspose() {
        return `${this.transpose_accept_media_video}`;
    }

    public get option_set() {
        return this.dtm.option_set;
    }
    public set option_set(value) {
        this.dtm.option_set = value;
    }

    public get rotation_physical() {
        return this.dtm.rotation_physical;
    }
    public set rotation_physical(value) {
        this.dtm.rotation_physical = value;
    }

    public get isPortrait() {
        let ret = false;
        if (this.width > this.height) {
            ret = this.rotation_physical === DeviceRotation.Clockwise90 || this.rotation_physical === DeviceRotation.Clockwise270;
        } else if (this.width < this.height) {
            ret = this.rotation_physical === DeviceRotation.Default || this.rotation_physical === DeviceRotation.Clockwise180;
        }
        return ret;
    }

    public get isLandscape() {
        let ret = false;
        if (this.width < this.height) {
            ret = this.rotation_physical === DeviceRotation.Clockwise90 || this.rotation_physical === DeviceRotation.Clockwise270;
        } else if (this.width > this.height) {
            ret = this.rotation_physical === DeviceRotation.Default || this.rotation_physical === DeviceRotation.Clockwise180;
        }
        return ret;
    }

    public get rotation_os() {
        return this.dtm.rotation_os;
    }
    public set rotation_os(value) {
        this.dtm.rotation_os = value;
    }

    public get rotation_container() {
        return this.dtm.rotation_container;
    }
    public set rotation_container(value) {
        this.dtm.rotation_container = value;
    }

    public get rotation_accept_media_video() {
        return this.dtm.rotation_accept_media_video;
    }
    public set rotation_accept_media_video(value) {
        this.dtm.rotation_accept_media_video = value;
    }

    public get transpose_accept_media_video() {
        return this.dtm.transpose_accept_media_video;
    }
    public set transpose_accept_media_video(value) {
        this.dtm.transpose_accept_media_video = value;
    }

    public get ext_id() {
        return this.dtm.ext_id;
    }
    public set ext_id(value) {
        this.dtm.ext_id = value;
    }

    public get location_id() {
        return this.dtm.location_id;
    }
    public set location_id(value) {
        this.dtm.location_id = value;
    }

    public get locationName() {
        let ret = 'Location: ' + this.dtm.location_id;
        if (this.dtm.location) {
            ret = this.dtm.location.name;
        }
        return ret;
    }

    public get locationLookup() { return this.getLookupProperty(() => this.dtm.location_id, (x) => this.createLookup(this.dtm.location_id, this.locationName)); }
    public set locationLookup(value) { this.setLookupProperty(() => this.dtm.location_id, (x) => { this.dtm.location_id = x; }, value, (item) => item.id); }


    public get type() {
        return this.dtm.type_id;
    }
    public set type(value) {
        this.dtm.type_id = value;
    }

    public get typeName() {
        return DeviceType[this.dtm.type_id] ? DeviceType[this.dtm.type_id]
            .replace(/([a-z])([A-Z])/g, '$1 $2').trim() : 'enum-missing';
    }

    public get type_lookup() { return this.getLookupProperty(() => this.dtm.type_id, (x) => this.createLookup(this.dtm.type_id, this.typeName)); }
    public set type_lookup(value) { this.setLookupProperty(() => this.dtm.type_id, (x) => { this.dtm.type_id = x; }, value, (item) => item.id); }

    public get ipv4() {
        return this.dtm.ipv4;
    }
    public set ipv4(value) {
        this.dtm.ipv4 = value;
    }

    public get macWired() {
        return this.dtm.mac_wired;
    }
    public set macWired(value) {
        this.dtm.mac_wired = value;
    }

    public get macWireless() {
        return this.dtm.mac_wireless;
    }
    public set macWireless(value) {
        this.dtm.mac_wireless = value;
    }

    public get externalPortalUrl() {
        if (this.dtm.description) {
            const descriptionLines = this.dtm.description.split('\n');
            const match = Enumerable.AsEnumerable(descriptionLines).FirstOrDefault((x) => x.indexOf('ExternalPortalURL:') > -1);
            if (match) {
                return match.split('ExternalPortalURL:')[1].trim().split(' ')[0];
            }
        }
        return '';
    }

    public get hasWindowsDevicePortal() {
        return this.externalPortalUrl && this.externalPortalUrl.indexOf('8080') > -1;
    }

    public get masterSlaveDevicesLookup() {
        if (!this._masterSlaveDevicesLookup && this.dtm.deviceDevices) {
            this._masterSlaveDevicesLookup = Enumerable.AsEnumerable(this.dtm.deviceDevices)
                .Where((x) => x.relation_type_id === DeviceDeviceRelationType.Master_Slave
                    && x.device_left_id === this.id).Select((x) => {
                        return {
                            id: x.device_right_id,
                            name: 'ID: ' + x.device_right_id
                        };
                    }).ToArray();
        }
        return /*where lookup */ this._masterSlaveDevicesLookup;
    }
    public set masterSlaveDevicesLookup(values) {

        if (this.dtm.deviceDevices.length) {
            // clear Master_Slave relations
            const toClear = Enumerable.AsEnumerable(this.dtm.deviceDevices)
                .Where((x) => x.relation_type_id === DeviceDeviceRelationType.Master_Slave
                    && x.device_left_id === this.id).ToArray();

            toClear.forEach((x) => {
                this.dtm.deviceDevices.splice(this.dtm.deviceDevices.indexOf(x), 1);
            });
        }

        if (values) {
            if (!Array.isArray(values)) {
                values = [values];
            }
            // recreate relations
            values.forEach((value) => {
                if (value as any !== 'none') {
                    const empty = new DeviceDeviceRelation();
                    empty.type_id = DeviceDeviceRelationType.Master_Slave;
                    empty.left_id = this.id;
                    empty.right_id = value.id;
                    this.dtm.deviceDevices.push(empty.dtm);
                }
            });
        }

        this._masterSlaveDevicesLookup = values;
    }
    public get txTunerDevicesLookup() {
        if (!this._txTunerDevicesLookup && this.dtm.deviceDevices) {
            this._txTunerDevicesLookup = Enumerable.AsEnumerable(this.dtm.deviceDevices)
                .Where((x) => x.relation_type_id === DeviceDeviceRelationType.Tx_Tuner
                    && x.device_left_id === this.id).Select((x) => {
                        return {
                            id: x.device_right_id,
                            name: 'ID: ' + x.device_right_id
                        };
                    }).ToArray();
        }
        return /*where lookup*/ this._txTunerDevicesLookup;
    }
    public set txTunerDevicesLookup(values) {

        if (this.dtm.deviceDevices.length) {
            // clear  relations
            const toClear = Enumerable.AsEnumerable(this.dtm.deviceDevices)
                .Where((x) => x.relation_type_id === DeviceDeviceRelationType.Tx_Tuner
                    && x.device_left_id === this.id).ToArray();

            toClear.forEach((x) => {
                this.dtm.deviceDevices.splice(this.dtm.deviceDevices.indexOf(x), 1);
            });
        }

        if (values) {
            if (!Array.isArray(values)) {
                values = [values];
            }
            // recreate relations
            values.forEach((value) => {
                if (value as any !== 'none') {
                    const empty = new DeviceDeviceRelation();
                    empty.type_id = DeviceDeviceRelationType.Tx_Tuner;
                    empty.left_id = this.id;
                    empty.right_id = value.id;
                    this.dtm.deviceDevices.push(empty.dtm);
                }
            });
        }

        this._txTunerDevicesLookup = values;
    }
    public get rxSignageDevicesLookup() {
        if (!this._rxSignageDevicesLookup && this.dtm.deviceDevices) {
            this._rxSignageDevicesLookup = Enumerable.AsEnumerable(this.dtm.deviceDevices)
                .Where((x) => x.relation_type_id === DeviceDeviceRelationType.Rx_SignageMonitor
                    && x.device_left_id === this.id).Select((x) => {
                        return {
                            id: x.device_right_id,
                            name: x.deviceRight ? x.deviceRight.name : 'ID: ' + x.device_right_id
                        };
                    }).ToArray();
        }
        return /*where lookup*/ this._rxSignageDevicesLookup;
    }
    public set rxSignageDevicesLookup(values) {

        if (this.dtm.deviceDevices.length) {
            // clear  relations
            const toClear = Enumerable.AsEnumerable(this.dtm.deviceDevices)
                .Where((x) => x.relation_type_id === DeviceDeviceRelationType.Rx_SignageMonitor
                    && x.device_left_id === this.id).ToArray();

            toClear.forEach((x) => {
                this.dtm.deviceDevices.splice(this.dtm.deviceDevices.indexOf(x), 1);
            });
        }

        if (values) {
            if (!Array.isArray(values)) {
                values = [values];
            }
            if (values.length && values[0] as any !== 'none') {
                // recreate relations
                values.forEach((value) => {
                    if (value as any !== 'none') {
                        const empty = new DeviceDeviceRelation();
                        empty.type_id = DeviceDeviceRelationType.Rx_SignageMonitor;
                        empty.left_id = this.id;
                        empty.right_id = value.id;
                        this.dtm.deviceDevices.push(empty.dtm);
                    }
                });
            }
        }

        this._rxSignageDevicesLookup = values;
    }


    public get contentSourceDevicesLookup() {
        if (!this._contentSourceDevicesLookup && this.dtm.deviceDevices) {
            this._contentSourceDevicesLookup = Enumerable.AsEnumerable(this.dtm.deviceDevices)
                .Where((x) => x.relation_type_id === DeviceDeviceRelationType.Content_Source
                    && x.device_left_id === this.id).Select((x) => {
                        return {
                            id: x.device_right_id,
                            name: x.deviceRight ? x.deviceRight.name : 'ID: ' + x.device_right_id
                        };
                    }).ToArray();
        }
        return /*where lookup*/ this._contentSourceDevicesLookup;
    }
    public set contentSourceDevicesLookup(values) {

        if (this.dtm.deviceDevices.length) {
            // clear  relations
            const toClear = Enumerable.AsEnumerable(this.dtm.deviceDevices)
                .Where((x) => x.relation_type_id === DeviceDeviceRelationType.Content_Source
                    && x.device_left_id === this.id).ToArray();

            toClear.forEach((x) => {
                this.dtm.deviceDevices.splice(this.dtm.deviceDevices.indexOf(x), 1);
            });
        }

        if (values) {
            if (!Array.isArray(values)) {
                values = [values];
            }
            if (values.length && values[0] as any !== 'none') {
                // recreate relations
                values.forEach((value) => {
                    if (value as any !== 'none') {
                        const empty = new DeviceDeviceRelation();
                        empty.type_id = DeviceDeviceRelationType.Content_Source;
                        empty.left_id = this.id;
                        empty.right_id = value.id;
                        this.dtm.deviceDevices.push(empty.dtm);
                    }
                });
            }
        }

        this._contentSourceDevicesLookup = values;
    }




    public get relatedDevicesNames() {
        if (!this._relatedDevicesNames && this.dtm.deviceDevices) {
            this._relatedDevicesNames = Enumerable.AsEnumerable(this.dtm.deviceDevices).Where((x) => !!x.deviceRight)
                .Select((x) => x.deviceRight.name).ToArray().join(', ');
        }
        return /*fix*/ this._relatedDevicesNames;
    }

    public get channel_lookups() { return this.getLookupArray(() => this.dtm.deviceChannels, (x) => this.createLookup(x.channel_id, x.channel?.name)); }
    public set channel_lookups(value) { this.setLookupArray(() => this.dtm.deviceChannels, (x) => this.dtm.deviceChannels = x, value, (item) => DeviceChannelRelation.getInstance(this.id, item.id).dtm); }


    public get channels_display_name() {

        let channels: Array<ChannelBase<IChannelBase>> = [];

        if (this.dtm.deviceChannels) {
            channels = Enumerable.AsEnumerable(this.dtm.deviceChannels).Select((x) => new ChannelBase<IChannelBase>(x.channel)).ToArray();
        }

        return Enumerable.AsEnumerable(channels).Distinct((x) => x.id).Select((x) => x.name).ToArray().join(', ');

    }

    public get extContentSourceDeviceRelations() { return this.getModelArray(() => this.dtm.extContentSourceDevices, (x) => new DeviceExtContentSourceRelation(x), PropertyUpdateMode.Clear); }
    public set extContentSourceDeviceRelations(value) { this.setModelArray(() => this.dtm.extContentSourceDevices, (x) => this.dtm.extContentSourceDevices = x, value, PropertyUpdateMode.Clear); }

    public get screengroupLookups() {
        return this.getLookupArray(() => this.dtm.screengroupDevices, (x) => {
            return {
                id: x.screengroup_id,
                name: x.screengroup.name,
                type: x.screengroup.type,
                acronym: x.screengroup.acronym
            };
        });
    }
    public set screengroupLookups(value) { this.setLookupArray(() => this.dtm.screengroupDevices, (x) => this.dtm.screengroupDevices = x, value, (item) => DeviceScreenGroupRelation.getInstance(this.id, item.id).dtm); }


    public get groups_name() {
        if (this.dtm.screengroupDevices) {
            const items = Enumerable.AsEnumerable(this.dtm.screengroupDevices).Where((x) => !!x.screengroup).Select((x) => x.screengroup).ToArray();
            return ScreenGroup.get_name(items);
        }
        return '';
    }

    public get group_acronym_name() {
        if (this.dtm.screengroupDevices) {
            const items = Enumerable.AsEnumerable(this.dtm.screengroupDevices).Where((x) => !!x.screengroup).Select((x) => x.screengroup).ToArray();
            return ScreenGroup.get_acronym_name(items, true);
        }
        return '';
    }

    public get deviceAuditoriumRelations() { return this.getModelArray(() => this.dtm.deviceAuditoriums, (x) => new DeviceAuditoriumRelation(x), PropertyUpdateMode.Clear); }
    public set deviceAuditoriumRelations(value) { this.setModelArray(() => this.dtm.deviceAuditoriums, (x) => this.dtm.deviceAuditoriums = x, value, PropertyUpdateMode.Clear); }

    public get timeRangeLookups() { return this.getLookupArray(() => this.dtm.timerangeRelations, (x) => this.createLookup(x.timerange_id, x.timerange?.name)); }
    public set timeRangeLookups(value) { this.setLookupArray(() => this.dtm.timerangeRelations, (x) => this.dtm.timerangeRelations = x, value, (item) => TimeRangeRelation.getInstance(this.id, item.id).dtm); }


    public get deviceSalesPointRelations() { return this.getModelArray(() => this.dtm.salespointDevices, (x) => new DeviceSalesPointRelation(x), PropertyUpdateMode.Clear); }
    public set deviceSalesPointRelations(value) { this.setModelArray(() => this.dtm.salespointDevices, (x) => this.dtm.salespointDevices = x, value, PropertyUpdateMode.Clear); }


    public get salesPointLookups() { return this.getLookupArray(() => this.dtm.salespointDevices, (x) => this.createLookup(x.salespoint_id, x.salespoint?.name)); }
    public set salesPointLookups(value) { this.setLookupArray(() => this.dtm.salespointDevices, (x) => this.dtm.salespointDevices = x, value, (item) => DeviceSalesPointRelation.getInstance(this.id, item.id).dtm); }

    public get tagsLookups() { return this.getLookupArray(() => this.dtm.deviceTags, (x) => this.createLookup(x.tag_id, x.tag?.name)); }
    public set tagsLookups(value) { this.setLookupArray(() => this.dtm.deviceTags, (x) => this.dtm.deviceTags = x, value, (item) => DeviceTagRelation.getInstance(this.id, item.id).dtm); }


    
    public get tags_display_name() {

        let tags: Array<ITag> = [];

        if (this.dtm.deviceTags) {
            tags = Enumerable.AsEnumerable(this.dtm.deviceTags).Select(x =>x.tag).ToArray();
        }

        return Enumerable.AsEnumerable(tags).Distinct((x) => x.id).Select((x) => x.name).ToArray().join(', ');
    }


    public get auditoriums_display_name() {

        let auditoriums: Array<IAuditorium> = [];

        if (this.dtm.deviceAuditoriums) {
            auditoriums = Enumerable.AsEnumerable(this.dtm.deviceAuditoriums).Select(x =>x.auditorium).ToArray();
        }

        return Enumerable.AsEnumerable(auditoriums).Distinct((x) => x.id).Select((x) => x.name).ToArray().join(', ');
    }

    public get optionOldMediaCacheUrl() {
        return this.dtm.option_set_fake ? this.dtm.option_set_fake.mediaCacheUrl : null;
    }
    public set optionOldMediaCacheUrl(value) {
        this.ensureDeviceOptionSet();
        if (value && value.length) {
            this.dtm.option_set_fake.mediaCacheUrl = value;
        } else {
            delete this.dtm.option_set_fake.mediaCacheUrl;
        }
        this.updateOptionSet();
    }


    public get optionMediaCacheUrl() {
        return this.dtm.option_set_fake && this.dtm.option_set_fake.player_option_set ? this.dtm.option_set_fake.player_option_set.mediaCacheUrl : null;
    }
    public set optionMediaCacheUrl(value) {
        this.ensureDevicePlayerOptionSet();
        if (value && value.length) {
            this.dtm.option_set_fake.player_option_set.mediaCacheUrl = value;
        } else {
            delete this.dtm.option_set_fake.player_option_set.mediaCacheUrl;
        }

        this.updateOptionSet();
    }


    public get optionUseFileType() {
        return this.dtm.option_set_fake && this.dtm.option_set_fake.playlist_option_set ? this.dtm.option_set_fake.playlist_option_set.useFileType : null;
    }
    public set optionUseFileType(value) {
        this.ensureDevicePlaylistOptionSet();
        if (value !== null) {
            this.dtm.option_set_fake.playlist_option_set.useFileType = value;
        } else {
            delete this.dtm.option_set_fake.playlist_option_set.useFileType;
        }
        this.updateOptionSet();
    }


    public get optionCalculationFrequency() {
        return this.dtm.option_set_fake && this.dtm.option_set_fake.playlist_option_set ? this.dtm.option_set_fake.playlist_option_set.calculationFrequency : null;
    }
    public set optionCalculationFrequency(value) {
        this.ensureDevicePlaylistOptionSet();
        if (value !== null) {
            this.dtm.option_set_fake.playlist_option_set.calculationFrequency = value;
        } else {
            delete this.dtm.option_set_fake.playlist_option_set.calculationFrequency;
        }
        this.updateOptionSet();
    }

    public get optionApectRatioRestrictionMode() {
        return this.dtm.option_set_fake && this.dtm.option_set_fake.playlist_option_set ? this.dtm.option_set_fake.playlist_option_set.apect_ratio_restriction_mode : null;
    }
    public set optionApectRatioRestrictionMode(value) {
        this.ensureDevicePlaylistOptionSet();
        if (value !== null) {
            this.dtm.option_set_fake.playlist_option_set.apect_ratio_restriction_mode = value;
        } else {
            delete this.dtm.option_set_fake.playlist_option_set.apect_ratio_restriction_mode;
        }
        this.updateOptionSet();
    }


    public get optionCalculationLength() {
        return this.dtm.option_set_fake && this.dtm.option_set_fake.playlist_option_set ? this.dtm.option_set_fake.playlist_option_set.calculationLength : null;
    }
    public set optionCalculationLength(value) {
        this.ensureDevicePlaylistOptionSet();
        if (value !== null) {
            this.dtm.option_set_fake.playlist_option_set.calculationLength = value;
        } else {
            delete this.dtm.option_set_fake.playlist_option_set.calculationLength;
        }
        this.updateOptionSet();
    }

    
    public get optionPlayerReloadInterval() {
        return this.dtm.option_set_fake && this.dtm.option_set_fake.player_option_set ? this.dtm.option_set_fake.player_option_set.reloadInterval : null;
    }
    public set optionPlayerReloadInterval(value) {
        this.ensureDevicePlayerOptionSet();
        if (value !== null) {
            this.dtm.option_set_fake.player_option_set.reloadInterval = value;
        } else {
            delete this.dtm.option_set_fake.player_option_set.reloadInterval;
        }
        this.updateOptionSet();
    }
    



    public get optionUseVideoHack() {
        return this.dtm.option_set_fake && this.dtm.option_set_fake.player_option_set ? this.dtm.option_set_fake.player_option_set.useVideoHack : null;
    }
    public set optionUseVideoHack(value) {
        this.ensureDevicePlayerOptionSet();
        if (value !== null) {
            this.dtm.option_set_fake.player_option_set.useVideoHack = value;
        } else {
            delete this.dtm.option_set_fake.player_option_set.useVideoHack;
        }
        this.updateOptionSet();
    }

    public get optionDebugMode() {
        return this.dtm.option_set_fake && this.dtm.option_set_fake.player_option_set ? this.dtm.option_set_fake.player_option_set.debugMode : null;
    }
    public set optionDebugMode(value) {
        this.ensureDevicePlayerOptionSet();
        if (value !== null) {
            this.dtm.option_set_fake.player_option_set.debugMode = value;
        } else {
            delete this.dtm.option_set_fake.player_option_set.debugMode;
        }
        this.updateOptionSet();
    }

    // optionPlayerScheme
    public get optionPlayerScheme() {
        return this.dtm.option_set_fake && this.dtm.option_set_fake.player_option_set ? this.dtm.option_set_fake.player_option_set.playerScheme : null;
    }
    public set optionPlayerScheme(value) {
        this.ensureDevicePlayerOptionSet();
        if (value !== null) {
            this.dtm.option_set_fake.player_option_set.playerScheme = value;
        } else {
            delete this.dtm.option_set_fake.player_option_set.playerScheme;
        }
        this.updateOptionSet();
    }

    public get optionAllowPlaylistFallback() {
        return this.dtm.option_set_fake && this.dtm.option_set_fake.player_option_set ? this.dtm.option_set_fake.player_option_set.allowPlaylistFallback : null;
    }
    public set optionAllowPlaylistFallback(value) {
        this.ensureDevicePlayerOptionSet();
        if (value !== null) {
            this.dtm.option_set_fake.player_option_set.allowPlaylistFallback = value;
        } else {
            delete this.dtm.option_set_fake.player_option_set.allowPlaylistFallback;
        }
        this.updateOptionSet();
    }

    public get optionUseTexture() {
        return this.dtm.option_set_fake && this.dtm.option_set_fake.player_option_set ? this.dtm.option_set_fake.player_option_set.useTexture : null;
    }
    public set optionUseTexture(value) {
        this.ensureDevicePlayerOptionSet();
        if (value !== null) {
            this.dtm.option_set_fake.player_option_set.useTexture = value;
        } else {
            delete this.dtm.option_set_fake.player_option_set.useTexture;
        }
        this.updateOptionSet();
    }

    public get optionEnableSound() {
        return this.dtm.option_set_fake && this.dtm.option_set_fake.player_option_set ? this.dtm.option_set_fake.player_option_set.enableSound : null;
    }
    public set optionEnableSound(value) {
        this.ensureDevicePlayerOptionSet();
        if (value !== null) {
            this.dtm.option_set_fake.player_option_set.enableSound = value;
        } else {
            delete this.dtm.option_set_fake.player_option_set.enableSound;
        }
        this.updateOptionSet();
    }

    public get optionEnableTimeSync() {
        return this.dtm.option_set_fake && this.dtm.option_set_fake.player_option_set ? this.dtm.option_set_fake.player_option_set.enableTimeSync : null;
    }
    public set optionEnableTimeSync(value) {
        this.ensureDevicePlayerOptionSet();
        if (value !== null) {
            this.dtm.option_set_fake.player_option_set.enableTimeSync = value;
        } else {
            delete this.dtm.option_set_fake.player_option_set.enableTimeSync;
        }
        this.updateOptionSet();
    }

    public get optionHideBackground() {
        return this.dtm.option_set_fake && this.dtm.option_set_fake.player_option_set ? this.dtm.option_set_fake.player_option_set.hideBackground : null;
    }
    public set optionHideBackground(value) {
        this.ensureDevicePlayerOptionSet();
        if (value !== null) {
            this.dtm.option_set_fake.player_option_set.hideBackground = value;
        } else {
            delete this.dtm.option_set_fake.player_option_set.hideBackground;
        }
        this.updateOptionSet();
    }

    public get optionWebPlayerHeight() {
        return this.dtm.option_set_fake && this.dtm.option_set_fake.player_option_set ? this.dtm.option_set_fake.player_option_set.webPlayerHeight : null;
    }
    public set optionWebPlayerHeight(value) {
        this.ensureDevicePlayerOptionSet();
        if (value > 0) {
            this.dtm.option_set_fake.player_option_set.webPlayerHeight = value;
        } else {
            delete this.dtm.option_set_fake.player_option_set.webPlayerHeight;
        }
        this.updateOptionSet();
    }

    public get optionWebPlayerWidth() {
        return this.dtm.option_set_fake && this.dtm.option_set_fake.player_option_set ? this.dtm.option_set_fake.player_option_set.webPlayerWidth : null;
    }
    public set optionWebPlayerWidth(value) {
        this.ensureDevicePlayerOptionSet();
        if (value > 0) {
            this.dtm.option_set_fake.player_option_set.webPlayerWidth = value;
        } else {
            delete this.dtm.option_set_fake.player_option_set.webPlayerWidth;
        }
        this.updateOptionSet();
    }

    public get optionWebPlayerTop() {
        return this.dtm.option_set_fake && this.dtm.option_set_fake.player_option_set ? this.dtm.option_set_fake.player_option_set.webPlayerTop : null;
    }
    public set optionWebPlayerTop(value) {
        this.ensureDevicePlayerOptionSet();
        if (value > 0 || value < 0) {
            this.dtm.option_set_fake.player_option_set.webPlayerTop = value;
        } else {
            delete this.dtm.option_set_fake.player_option_set.webPlayerTop;
        }
        this.updateOptionSet();
    }

    public get optionWebPlayerLeft() {
        return this.dtm.option_set_fake && this.dtm.option_set_fake.player_option_set ? this.dtm.option_set_fake.player_option_set.webPlayerLeft : null;
    }
    public set optionWebPlayerLeft(value) {
        this.ensureDevicePlayerOptionSet();
        if (value > 0 || value < 0) {
            this.dtm.option_set_fake.player_option_set.webPlayerLeft = value;
        } else {
            delete this.dtm.option_set_fake.player_option_set.webPlayerLeft;
        }
        this.updateOptionSet();
    }

    public get optionHackCode() {
        return this.dtm.option_set_fake && this.dtm.option_set_fake.player_option_set ? this.dtm.option_set_fake.player_option_set.hackCode : null;
    }
    public set optionHackCode(value) {
        this.ensureDevicePlayerOptionSet();
        if (value && value.length) {
            this.dtm.option_set_fake.player_option_set.hackCode = value;
        } else {
            delete this.dtm.option_set_fake.player_option_set.hackCode;
        }
        this.updateOptionSet();
    }

    public get optionCacheBustAdd() {
        return this.dtm.option_set_fake && this.dtm.option_set_fake.player_option_set ? this.dtm.option_set_fake.player_option_set.cacheBustAdd : null;
    }
    public set optionCacheBustAdd(value) {
        this.ensureDevicePlayerOptionSet();
        if (value && value.length) {
            this.dtm.option_set_fake.player_option_set.cacheBustAdd = value;
        } else {
            delete this.dtm.option_set_fake.player_option_set.cacheBustAdd;
        }
        this.updateOptionSet();
    }

    public get optionRenderOptions() {
        return this.dtm.option_set_fake && this.dtm.option_set_fake.player_option_set ? this.dtm.option_set_fake.player_option_set.renderOptions : null;
    }
    public set optionRenderOptions(value) {
        this.ensureDevicePlayerOptionSet();
        if (value && value.length) {
            this.dtm.option_set_fake.player_option_set.renderOptions = value;
        } else {
            delete this.dtm.option_set_fake.player_option_set.renderOptions;
        }
        this.updateOptionSet();
    }

    public get optionUseInputInBackground() {
        return this.dtm.option_set_fake && this.dtm.option_set_fake.player_option_set ? this.dtm.option_set_fake.player_option_set.useInputInBackground : null;
    }
    public set optionUseInputInBackground(value) {
        this.ensureDevicePlayerOptionSet();
        if (value !== null) {
            this.dtm.option_set_fake.player_option_set.useInputInBackground = value;
        } else {
            delete this.dtm.option_set_fake.player_option_set.useInputInBackground;
        }
        this.updateOptionSet();
    }

    public get optionOnloadPlayContentFromStart() {
        return this.dtm.option_set_fake && this.dtm.option_set_fake.player_option_set ? this.dtm.option_set_fake.player_option_set.onloadPlayContentFromStart : null;
    }
    public set optionOnloadPlayContentFromStart(value) {
        this.ensureDevicePlayerOptionSet();
        if (value !== null) {
            this.dtm.option_set_fake.player_option_set.onloadPlayContentFromStart = value;
        } else {
            delete this.dtm.option_set_fake.player_option_set.onloadPlayContentFromStart;
        }
        this.updateOptionSet();
    }

    

    public get optionInstanceBackgroundColor() {
        return this.dtm.option_set_fake && this.dtm.option_set_fake.player_option_set ? this.dtm.option_set_fake.player_option_set.instanceBackgroundColor : null;
    }
    public set optionInstanceBackgroundColor(value) {
        this.ensureDevicePlayerOptionSet();
        if (value && value.length) {
            this.dtm.option_set_fake.player_option_set.instanceBackgroundColor = value;
        } else {
            delete this.dtm.option_set_fake.player_option_set.instanceBackgroundColor;
        }
        this.updateOptionSet();
    }

    public get optionTransitionMode() {
        return this.dtm.option_set_fake && this.dtm.option_set_fake.player_option_set ? this.dtm.option_set_fake.player_option_set.transitionMode : null;
    }
    public set optionTransitionMode(value) {
        this.ensureDevicePlayerOptionSet();
        if (value !== null) {
            this.dtm.option_set_fake.player_option_set.transitionMode = value;
        } else {
            delete this.dtm.option_set_fake.player_option_set.transitionMode;
        }
        this.updateOptionSet();
    }

    public get optionTransitionDirection() {
        return this.dtm.option_set_fake && this.dtm.option_set_fake.player_option_set ? this.dtm.option_set_fake.player_option_set.transitionDirection : null;
    }
    public set optionTransitionDirection(value) {
        this.ensureDevicePlayerOptionSet();
        if (value !== null) {
            this.dtm.option_set_fake.player_option_set.transitionDirection = value;
        } else {
            delete this.dtm.option_set_fake.player_option_set.transitionDirection;
        }
        this.updateOptionSet();
    }

    public get optionEnvironmentName() {
        return this.dtm.option_set_fake && this.dtm.option_set_fake.player_option_set ? this.dtm.option_set_fake.player_option_set.environmentName : null;
    }
    public set optionEnvironmentName(value) {
        this.ensureDevicePlayerOptionSet();
        if (value && value.length) {
            this.dtm.option_set_fake.player_option_set.environmentName = value;
        } else {
            delete this.dtm.option_set_fake.player_option_set.environmentName;
        }
        this.updateOptionSet();
    }

    public get optionWebPlayerVersionCurrent() {
        return this.optionWebPlayerVersion && this.optionWebPlayerVersion.length ? this.optionWebPlayerVersion : defaultWebPlayerVersion;
    }

    public get optionWebPlayerVersion() {
        return this.dtm.option_set_fake && this.dtm.option_set_fake.player_option_set ? this.dtm.option_set_fake.player_option_set.webPlayerVersion : null;
    }

    public set optionWebPlayerVersion(value) {
        this.ensureDevicePlayerOptionSet();
        if (value && value.length) {
            this.dtm.option_set_fake.player_option_set.webPlayerVersion = value;
        } else {
            delete this.dtm.option_set_fake.player_option_set.webPlayerVersion;
        }
        this.updateOptionSet();
    }

    public get optionPlayerTimeMatchMode() {
        return this.dtm.option_set_fake && this.dtm.option_set_fake.player_option_set ? this.dtm.option_set_fake.player_option_set.timeMatchMode : null;
    }
    public set optionPlayerTimeMatchMode(value) {
        this.ensureDevicePlayerOptionSet();
        if (value) {
            this.dtm.option_set_fake.player_option_set.timeMatchMode = value;
        } else {
            delete this.dtm.option_set_fake.player_option_set.timeMatchMode;
        }
        this.updateOptionSet();
    }



    public get playlistV3ViewerUrl() {

        // TOOD: add proper way
        if (this.dtm.deviceChannels && this.dtm.deviceChannels.length) {
            return `${environment.PLAYLIST_TEST_URL}/viewer?deviceChannelID=${this.dtm.deviceChannels[0].id}`;
        }

        return null;
    }




    private _masterSlaveDevicesLookup: ILookup[];

    private _txTunerDevicesLookup: ILookup[];

    private _rxSignageDevicesLookup: ILookup[];

    private _contentSourceDevicesLookup: ILookup[];

    private _relatedDevicesNames: string = null;

    public static getCleanClone(dtm: IDevice, cloneDTM = true) {

        if (cloneDTM) {
            dtm = JSON.parse(JSON.stringify(dtm)) as IDevice;
        }

        dtm.name = 'Clone of: ' + dtm.name;

        // dtm.name = dtm.name + '-FULLIDLE';
        // dtm.ext_id = dtm.ext_id + '-FULLIDLE';

        const cloneOfId = dtm.id;
        dtm.id = 0;

        if (dtm.deviceChannels) {
            dtm.deviceChannels.forEach((x) => {
                x.id = 0;
                x.device_id = 0;
            });
        }

        if (dtm.screengroupDevices) {
            dtm.screengroupDevices.forEach((x) => {
                x.device_id = 0;
            });
        }

        if (dtm.deviceDevices) {
            dtm.deviceDevices.forEach((x) => {
                if (x.device_left_id === cloneOfId) {
                    x.deviceLeft = null;
                    x.device_left_id = 0;
                }
                if (x.device_right_id === cloneOfId) {
                    x.deviceRight = null;
                    x.device_right_id = 0;
                }
            });
        }

        if (dtm.extContentSourceDevices) {
            dtm.extContentSourceDevices.forEach((x) => {
                x.device_id = 0;
            });
        }

        if (dtm.deviceAuditoriums) {
            dtm.deviceAuditoriums.forEach((x) => {
                x.device_id = 0;
            });
        }

        return new Device(dtm);
    }

    public update(dtm: IDevice, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);

        // create fake dtm props
        if (dtm.option_set) {
            dtm.option_set = dtm.option_set.replace('“', '"').replace('”', '"');
            this.dtm.option_set_fake = JSON.parse(dtm.option_set);
        }


    }

    protected clearLocalProps() {
        super.clearLocalProps();

        this._masterSlaveDevicesLookup = null;
        this._txTunerDevicesLookup = null;
        this._rxSignageDevicesLookup = null;
        this._relatedDevicesNames = null;
    }


    private updateOptionSet() {
        if (this.dtm.option_set_fake) {
            this.dtm.option_set = JSON.stringify(this.dtm.option_set_fake);
        } else {
            this.dtm.option_set = '{}';
        }
    }

    public cleanForSave(dtm: IDevice, saver: ObjectType) {
        super.cleanForSave(dtm, saver);


        // refill actual value
        this.updateOptionSet();
        // cleanup fake
        delete dtm.option_set_fake;


        delete dtm.location;



        this.cleanDTMs(DeviceAuditoriumRelation, dtm.deviceAuditoriums, saver);
        this.cleanDTMs(DeviceChannelRelation, dtm.deviceChannels, saver);
        this.cleanDTMs(DeviceDeviceRelation, dtm.deviceDevices, saver);
        this.cleanDTMs(DeviceExtContentSourceRelation, dtm.extContentSourceDevices, saver);
        this.cleanDTMs(DeviceScreenGroupRelation, dtm.screengroupDevices, saver);
        this.cleanDTMs(TimeRangeRelation, dtm.timerangeRelations, saver);
        this.cleanDTMs(DeviceSalesPointRelation, dtm.salespointDevices, saver);
        this.cleanDTMs(DeviceTagRelation, dtm.deviceTags, saver);
    }

    private ensureDeviceOptionSet() {
        if (!this.dtm.option_set_fake || Array.isArray(this.dtm.option_set_fake)) {
            this.dtm.option_set_fake = {} as IDeviceOptionSet;
        }
    }
    private ensureDevicePlaylistOptionSet() {
        this.ensureDeviceOptionSet();
        if (!this.dtm.option_set_fake.playlist_option_set || Array.isArray(this.dtm.option_set_fake.playlist_option_set)) {
            this.dtm.option_set_fake.playlist_option_set = {} as IDevicePlaylistOptionSet;
        }
    }
    private ensureDevicePlayerOptionSet() {
        this.ensureDeviceOptionSet();
        if (!this.dtm.option_set_fake.player_option_set || Array.isArray(this.dtm.option_set_fake.player_option_set)) {
            this.dtm.option_set_fake.player_option_set = {} as IDevicePlayerOptionSet;
        }
    }
}
