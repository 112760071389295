import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import * as Enumerable from 'linq-es2015';

import { IFilterGroup } from './filter/filter-group.model';
import { ToasterService } from '../services/toaster.service';
import { throwError, Observable } from '../../../../node_modules/rxjs';
import { DataServiceType } from './enums/enums';

interface IValidationMessage {
  field: string;
  iterationKey: number;
  messages: string[];
  object: any;
  objectId: number;
  type: string;
}

export class BaseDataService {
  public apiBaseUrl = environment.VOOGSTV_CLIENTAPI_URL;
  public apiKey = environment.VOOGSTV_CLIENTAPI_KEY;

  constructor(protected toastr: ToasterService, private dataServiceType: DataServiceType = 'default') {
    // if (this.dataServiceType === 'html-content-api') {
    //   this.apiBaseUrl = environment.VOOGSTV_HTMLCONTENTAPI_URL;
    //   this.apiKey = environment.VOOGSTV_HTMLCONTENTAPI_KEY;
    // }

  }

  public get headers() {

    const accessToken = this.getDomainCookie('access-token-'+ environment.ENVIROMENT_NAME);

    // if (this.dataServiceType === 'html-content-api') {
    //   const headers = new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     'access-token': accessToken,
    //     'api-key': this.apiKey
    //   });

    //   return headers;
    // } else {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'access-token': accessToken
      });

      return headers;
    // }

  }

  public handleError(errorResponse: HttpErrorResponse, caught: Observable<any>, alertOnError = true) {

    let errorText = 'ERROR';

    console.log(errorResponse);

    if (errorResponse.message) {
      errorText = errorResponse.message;
    } else if (errorResponse.statusText) {
      errorText = errorResponse.statusText;
    }
    if (errorResponse.status === 401) {
      this.setDomainCookie('access-token-'+ environment.ENVIROMENT_NAME, '');
      window.location.reload();
    } else if (errorResponse.status === 403) {
      if (alertOnError) {
        this.toastr.alert('Permission denied', 'Forbidden'.toUpperCase());
      }
    } else if (errorResponse.status === 0) {
      if (alertOnError) {
        this.toastr.alert(errorText, errorResponse.statusText.toUpperCase());
      }
    } else {

      let errorObject = errorResponse.error;

      // /password/set-password has this response
      if (errorObject && errorObject.hasOwnProperty('messages') && errorObject.hasOwnProperty('errorCount') && errorObject.hasOwnProperty('data')
        && errorObject.errorCount === 0 && errorObject.messages.length === 0) {
        errorObject = errorObject.data; // should now be handeld on the last if
      }

      if (errorObject && errorObject.hasOwnProperty('messages') && errorObject.hasOwnProperty('errorCount')) {
        const errorMessages = errorObject.messages as IValidationMessage[];

        const jointMessage = Enumerable.AsEnumerable(errorMessages)
          .Where((x) => x.type === 'error')
          .SelectMany((x) => x.messages).ToArray().join('<br/>');

        if (jointMessage && jointMessage.length) {
          errorText = jointMessage;
          if (alertOnError) {
            this.toastr.alert(errorText, 'ERROR');
          }
        }

      } else if (errorObject && Array.isArray(errorObject) && errorObject.length
        && errorObject[0].hasOwnProperty('message') && errorObject[0].hasOwnProperty('field')) { // login has this type error model
        const jointMessage = Enumerable.AsEnumerable(errorObject)
          .Where((x) => x.message).Select((x) => x.message).ToArray().join('<br/>');

        if (jointMessage && jointMessage.length) {
          errorText = jointMessage;
          if (alertOnError) {
            this.toastr.alert(errorText, 'ERROR');
          }
        }
      } else if (errorObject && !Array.isArray(errorObject) && errorObject.hasOwnProperty('message')) { // 400 has this error model

        let title = 'ERROR';
        const jointMessage = errorObject.message;

        if (errorObject.hasOwnProperty('name')) {
          title = errorObject.name;
        }

        if (jointMessage && jointMessage.length) {
          errorText = jointMessage;
          if (alertOnError) {
            this.toastr.alert(errorText, title);
          }
        }
      } else if (errorObject && !Array.isArray(errorObject) && Object.keys(errorObject).length
        && Array.isArray(errorObject[Object.keys(errorObject)[0]])) { // filed validation has this error model

        const jointMessage = Enumerable.AsEnumerable(Object.values(errorObject) as Array<[]>).SelectMany((x) => x).ToArray().join('<br/>');

        if (jointMessage && jointMessage.length) {
          errorText = jointMessage;
          if (alertOnError) {
            this.toastr.alert(errorText, 'ERROR');
          }
        }
      }
    }

    return throwError(errorText);
    // return Observable.throw(errorText);
  }

  public getDomainCookie(name: string) {
    const v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
  }

  public setDomainCookie(name: string, value: string, days: number = 365) {

    const d = new Date();
    d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
    let isSubdomain = false;
    let domain = this.getDomainFromHostName(document.location.hostname);

    if (domain === 'amazonaws.com' || domain === 'azurewebsites.net' || domain === 'appspot.com') {
      // these are actualy top level domains (like: com, ee and uk.com) so we can't set cookies for them
      // set for spesific hostmane
      domain = document.location.hostname;
    } else if (domain !== 'localhost') {
      isSubdomain = true;
    }

    if (days === 0) {
      const cookie = `${name}=${value};domain=${isSubdomain ? '.' : ''}${domain};path=/`;
      document.cookie = cookie;
    } else {
      const cookie = `${name}=${value};expires=${d.toUTCString()};domain=${isSubdomain ? '.' : ''}${domain};path=/`;
      document.cookie = cookie;
    }
  }

  
  public getCookie(name: string) {
    const v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
  }

  public setCookie(name: string, value: string, days: number = 365) {

    const d = new Date();
    d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
 

    if (days === 0) {
      const cookie = `${name}=${value};path=/`;
      document.cookie = cookie;
    } else {
      const cookie = `${name}=${value};expires=${d.toUTCString()};path=/`;
      document.cookie = cookie;
    }
  }

  private getDomainFromHostName(hostname: string) {




    const splits = hostname.split('.');

    if (splits.length > 2) { // if has a subdomain.
      splits.shift(); // remove first element;
      return splits.join('.');
    } else {
      return hostname;
    }
  }
}

export interface IListData<T> {
  totalCount: number;
  data: T[];
  filter: IFilterGroup[];
}

export interface IObjectData<T> {
  data: T;
  messages: any[];
  errorCount: number;
}
