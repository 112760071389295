import { PropertyIntentID, Status, StatusSystem, ObjectType } from '../enums/enums';
import * as Enumerable from 'linq-es2015';
import { Subject } from 'rxjs';
import { share } from 'rxjs/operators';
import { Show, IShow } from '../show/show.model';
import { BaseRelatedConfModel } from '../base-relatedconf.model';
import { EventResourceRelation } from './event-resource-relation.model';
import { IBaseRelatedConfModel } from '../base-relatedconf.model';
import { IEventResourceRelation } from './event-resource-relation.model';
import { IEventCampaignRelation, EventCampaignRelation } from './event-campaign-relation.model';
import { ILookup } from '../lookup.model';
import { Campaign } from '../campaign/campaign.model';
import { PropertyUpdateMode } from '../base.model';

export interface IEventBase extends IBaseRelatedConfModel {
    ext_id: number;
    parent_id: number;
    shows: IShow[];
    eventResource: IEventResourceRelation[];
    eventCampaigns: IEventCampaignRelation[];
}

export class EventBase<TShow extends Show<any>> extends BaseRelatedConfModel<IEventBase> {
    constructor(
        dtm: IEventBase = null,
        private createShowInstance: (dtm: IShow) => TShow = null) {
        super(dtm || {
            id: 0,
            client_id: 0,
            ext_id: 0,
            parent_id: 0,
            name: null,
            property_set: [],
            related_conf_base_id: 0,
            relatedConfBase: null,
            shows: null,
            eventResource: null,
            eventCampaigns: null,
            status: Status.Active,
            status_system: StatusSystem.Default,
            created_at: null,
            updated_at: null,
            created_by: null,
            updated_by: null

        });
        this.update(this.dtm, true, true);
    }

    private _displayName: string;
    public get displayName() {
        if (!this._displayName && this.propertySet) {
            const match = Enumerable.AsEnumerable(this.propertySet).Where((x) => !!x.intent)
                .FirstOrDefault((x) => x.intent.id === PropertyIntentID.GeneralTitle);
            if (match && match.values && match.values.length) {
                const match2 = Enumerable.AsEnumerable(match.values).FirstOrDefault((x) => (!!x.contentString && !!x.contentString.length) || (x.isOverrided && !!x.contentOverrideString && !!x.contentOverrideString.length));
                if (match2) {
                    if (match2.isOverrided) {
                        this._displayName = match2.contentOverrideString;
                    } else {
                        this._displayName = match2.contentString;
                    }
                } else {
                    this._displayName = '-';
                }
                // Enumerable.AsEnumerable(match.values).Select((x) => x.contentString).ToArray().join(', ');
            } else {
                this._displayName = 'Unknown';
            }
        } else if (!this._displayName) {
            this._displayName = `ID: ${this.id}`;
        }
        return /*fix*/ this._displayName;
    }

    public get ext_id() {
        return this.dtm.ext_id;
    }
    public set ext_id(value) {
        this.dtm.ext_id = value;
    }

    public get parent_id() {
        return this.dtm.parent_id;
    }
    public set parent_id(value) {
        this.dtm.parent_id = value;
    }

    public get shows(): TShow[] {
        if (this.createShowInstance != null) {
            return this.getModelArray(() => this.dtm.shows, this.createShowInstance, PropertyUpdateMode.Clear);
        } else {
            return this.getModelArray(() => this.dtm.shows, (x) => new Show(x) as TShow, PropertyUpdateMode.Clear);
        }
    }

    public set shows(value) { this.setModelArray(() => this.dtm.shows, (x) => this.dtm.shows = x, value as any, PropertyUpdateMode.Clear); }

    public get eventResources() { return this.getModelArray(() => this.dtm.eventResource, (x) => new EventResourceRelation(x), PropertyUpdateMode.Clear); }
    public set eventResources(value) { this.setModelArray(() => this.dtm.eventResource, (x) => this.dtm.eventResource = x, value, PropertyUpdateMode.Clear); }

    public get campaignLookups() { return this.getLookupArray(() => this.dtm.eventCampaigns, (x) => this.createLookup(x.campaign_id, new Campaign(x.campaign).name)); }
    public set campaignLookups(value) { this.setLookupArray(() => this.dtm.eventCampaigns, (x) => this.dtm.eventCampaigns = x, value, (item) => EventCampaignRelation.getInstance(this.id, item.id).dtm); }

    public get campaignsString() {
        if (this.campaignLookups && this.campaignLookups.length) {
            return Enumerable.AsEnumerable(this.campaignLookups)
                .Select((x) => x.name).Distinct().ToArray().join(', ');
        }
        return '';
    }

    public update(dtm: IEventBase, forceReplaceDtm = false, fromConstructor = false) {
        super.update(dtm, forceReplaceDtm);



    }

    public cleanForSave(dtm: IEventBase, saver: ObjectType) {
        super.cleanForSave(dtm, saver);

        this.cleanDTMs(EventResourceRelation, dtm.eventResource, saver);
        this.cleanDTMs(EventCampaignRelation, dtm.eventCampaigns, saver);
        this.cleanDTMs(Show, dtm.shows, saver);
    }

    protected clearLocalProps() {
        super.clearLocalProps();

        this._displayName = null;

    }



}
