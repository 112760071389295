import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { XhrUploadService } from '../xhr-upload.service';
import { ToasterService } from '../toaster.service';
import { IBlobProgress } from '../blob-progress.model';
import { MediaVersionStatusTechUpload, ResourceStatusTechUpload } from 'app/core/data/enums/enums';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AuthDataService } from 'app/core/data/auth/auth-data.service';

export interface IMediaVersionUploadResult {
  // isCompleted: boolean;
  blobID: number;
  location: string;
  // containerRef: string;
  // fileRef: string;
  mediaVersionStatusTechUpload: MediaVersionStatusTechUpload;
}

export interface IResourceUploadResult {
  // isCompleted: boolean;
  blobID: number;
  location: string;
  // containerRef: string;
  // fileRef: string;
  resourceStatusTechUpload: ResourceStatusTechUpload;
}

@Injectable({
  providedIn: 'root'
})
export class UploadApiService {
  private baseUrl = environment.VOOGSTV_UPLOADAPI_URL;
  private apiKey = environment.VOOGSTV_UPLOADAPI_KEY;
  constructor(private http: HttpClient, private xhrUploadService: XhrUploadService, private toastr: ToasterService, private authDataService: AuthDataService) {

  }

  public uploadMediaVersionFileBlob(
    mediaVersionID: number,
    blobID: number, blobTotalCount: number, contentType: string, fileName: string,
    blobProgress: IBlobProgress): Observable<IMediaVersionUploadResult> {

    const accessToken = this.authDataService.getDomainCookie('access-token-'+ environment.ENVIROMENT_NAME);

    const headers = new HttpHeaders({
      'access-token': accessToken,
      'api-key': this.apiKey,
      BlobTotalCount: blobTotalCount.toString(),
      BlobContentType: contentType,
      BlobFileName: encodeURIComponent(fileName),
    });

    const formData = new FormData();

    formData.append('files', blobProgress.blob, blobID.toString());

    return this.xhrUploadService
      .makeFileRequest<IMediaVersionUploadResult>(`${this.baseUrl}/mediaVersion/upload/${mediaVersionID}/${blobID}`,
        formData, headers, blobProgress)
      .pipe(catchError((err, caught) => this.handleError(err, caught)));

  }

  public uploadResourceFileBlob(
    resourceID: number, blobID: number, blobTotalCount: number, contentType: string,
    fileName: string, blobProgress: IBlobProgress): Observable<IResourceUploadResult> {
    const accessToken = this.authDataService.getDomainCookie('access-token-'+ environment.ENVIROMENT_NAME);

    const headers = new HttpHeaders({
      'access-token': accessToken,
      'api-key': this.apiKey,
      BlobTotalCount: blobTotalCount.toString(),
      BlobContentType: contentType,
      BlobFileName: encodeURIComponent(fileName),
    });

    const formData = new FormData();

    formData.append('files', blobProgress.blob, blobID.toString());

    return this.xhrUploadService.makeFileRequest<IMediaVersionUploadResult>(
      `${this.baseUrl}/resource/upload/${resourceID}/${blobID}`,
      formData, headers, blobProgress)
      .pipe(catchError(this.handleError));

  }

  private handleError(error: Response, caught: Observable<any>) {

    let errorText = 'ERROR';

    if (error.statusText) {
      errorText = error.statusText;
    }

    if (error.status === 401) {
      alert('Unauthorized request!');
      // window.location.reload(true);
    }

    return throwError(errorText);
  }
}
