import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
// import localeEt from '@angular/common/locales/global/et';
import { LOCALE_ID, NgModule, Optional, SkipSelf } from '@angular/core';
import { MatMomentDateModule, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { RouterModule } from '@angular/router';
import { RelatedConfBaseDataService } from 'app/core/data/related-conf-base/related-conf-base-data.service';
import { AuthGuardService } from '../auth/auth-guard.service';
import { AuthService } from '../auth/auth.service';
import { DeactivateGuardService } from '../auth/deactivate-guard.service';
import { NotAuthGuardService } from '../auth/not-auth-guard.service';
import { FormTypeBuilder } from '../shared/extends/angular-reactive-forms-helper';
import { SharedModule } from '../shared/shared.module';
import { AdSpaceDataService } from './data/ad-space/ad-space-data.service';
import { ApiAccessDataService } from './data/api-access/api-access-data.service';
import { AuditoriumDataService } from './data/auditorium/auditorium-data.service';
import { AuthDataService } from './data/auth/auth-data.service';
import { BackgroundJobDataService } from './data/background-job/background-job-data.service';
import { CampaignDataService } from './data/campaign/campaign-data.service';
import { BookingDataService } from './data/booking/booking-data.service';
import { ChannelDataService } from './data/channel/channel-data.service';
import { ClientDataService } from './data/client/client-data.service';
import { CollectionDataService } from './data/collection/collection-data.service';
import { InboxDataService } from './data/collection/inbox-data.service';
import { TrashDataService } from './data/collection/trash-data.service';
import { DeviceChannelDataService } from './data/device-channel/device-channel-data.service';
import { DeviceTypeDataService } from './data/device-type/device-type-data.service';
import { DeviceAuditoriumRelationDataService } from './data/device/device-auditorium-relation-data.service';
import { DeviceDataService } from './data/device/device-data.service';
import { DistributionChannelDataService } from './data/distribution-channel/distribution-channel-data.service';
import { EventDataService } from './data/event/event-data.service';
import { ExtCommandDataService } from './data/ext-command/ext-command-data.service';
import { ExtContentSourceDataService } from './data/ext-content-source/ext-content-source-data.service';
import { FloorMapDataService } from './data/floor-map/floor-map-data.service';
import { GenreDataService } from './data/genre/genre-data.service';
import { GenreTypeDataService } from './data/genre/genre-type-data.service';
import { LanguageDataService } from './data/language/language-data.service';
import { LocationDataService } from './data/location/location-data.service';
import { MediaTypeDataService } from './data/media-type/media-type-data.service';
import { MediaVersionDataService } from './data/media-version/media-version-data.service';
import { MediaDataService } from './data/media/media-data.service';
import { ProductGroupDataService } from './data/product-group/product-group-data.service';
import { ProductGroupTypeDataService } from './data/product-group/product-group-type-data.service';
import { ProductPriceGroupDataService } from './data/product-price-group/product-price-group-data.service';
import { ProductPriceDataService } from './data/product-price/product-price-data.service';
import { ProductDataService } from './data/product/product-data.service';
import { ProgramDataService } from './data/program/program-data.service';
import { RatingDataService } from './data/rating/rating-data.service';
import { ResourceTypeDataService } from './data/resource-type/resource-type-data.service';
import { ResourceDataService } from './data/resource/resouce-data.service';
import { ResourceMainTypeDataService } from './data/resource/resource-main-type-data.service';
import { ScreenGroupDataService } from './data/screen-group/screen-group-data.service';
import { ScreenGroupTypeDataService } from './data/screen-group/screen-group-type-data.service';
import { ScreenDataService } from './data/screen/screen-data.service';
import { ShowDataService } from './data/show/show-data.service';
import { SpotAppFilterRuleDataService } from './data/spot-app-filter-rule/spot-app-filter-rule-data.service';
import { SpotAppDataService } from './data/spot-app/spot-app-data.service';
import { SpotAppEqLevelDataService } from './data/spot-app/spot-app-eq-level-data.service';
import { SpotAppFreeSpaceFillModeDataService } from './data/spot-app/spot-app-free-space-fill-mode-data.service';
import { SpotAppEqMethodDataService } from './data/spot-app/spot-app-eq-method-data.service';
import { SpotAppInstanceDataModeDataService } from './data/spot-app/spot-app-instance-data-mode-data.service';
import { SpotDataService } from './data/spot/spot-data.service';
// import { SpotTriggerOffsetModeDataService } from './data/spot/spot-trigger-offset-mode-data.service';
import { SpotTypeDataService } from './data/spot/spot-type-data.service';
import { SpotRemoteTriggerTypeDataService } from './data/spot/spot-remote-trigger-type-data.service';
import { StatusDataService } from './data/status/status-data.service';
import { TagDataService } from './data/tag/tag-data.service';
import { TargetAudienceDataService } from './data/target-audience/target-audience-data.service';
import { TemplateBaseDataService } from './data/template/template-base-data.service';
import { TemplateInstanceDataService } from './data/template/template-instance-data.service';
import { TimeRangeDataService } from './data/time-range/time-range-data.service';
import { TimeRangeTypeDataService } from './data/time-range/time-range-type-data.service';
import { TimeZoneDataService } from './data/time-zone/time-zone-data.service';
import { UserDataService } from './data/user/user-data.service';
import { DeviceHubService } from './hub/device-hub.service';
import { HubService } from './hub/hub.service';
import { ProcessApiJobHubService } from './hub/processapi-job-hub.service';
import { ClientResolver } from './resolvers/client.resolver';
import { UserResolver } from './resolvers/user.resolver';
import { ApplicationInsightsService } from './services/application-insights.service';
import { DevicePortalApiService } from './services/device-portal-api/device-portal-api.service';
import { GlobalDialogService } from './services/global-dialog.service';
import { HelperService } from './services/helper.service';
import { LocaleSettingsService } from './services/locale-settings.service';
import { MediaVersionUploadService } from './services/media-version-upload.service';
import { ProcessApiService } from './services/process-api/process-api.service';
import { ResourceUploadService } from './services/resource-upload.service';
import { UploadApiService } from './services/upload-api/upload-api.service';
import { XhrUploadService } from './services/xhr-upload.service';
import { FileTypeDataService } from './data/file/file-type-data.service';
import { SalesPointDataService } from './data/sales-point/sales-point-data.service';
import { TransitionModeDataService } from './data/transition-mode/transition-mode-data.service';
import { TransitionDirectionDataService } from './data/transition-direction/transition-direction-data.service';
import { ProductProductRelationTypeDataService } from './data/product/product-product-relation-type-data.service';
import { PlaylistCalculationFrequencyDataService } from './data/playlist/playlist-calculation-frequency-data.service';
import { ResourceTypeIntentDataService } from './data/resource-type/resource-type-intent-data.service';
import { ProductPriceGroupTypeDataService } from './data/product-price-group/product-price-group-type-data.service';
import { ProductPriceGroupTargetTypeDataService } from './data/product-price-group/product-price-group-target-type-data.service';
import { SpotAppTypeDataService } from './data/spot-app/spot-app-type-data.service';
import { TemplateInstanceProductPriceFetchingRuleDataService } from './data/template/template-instance-product-price-fetching-rule-data.service';
import { TemplateInstanceProductGroupFetchingRuleDataService } from './data/template/template-instance-product-group-fetching-rule-data.service';
import { CampaignTypeDataService } from './data/campaign/campaign-type-data.service';
import { PlaylistApectRatioRestrictionModeDataService } from './data/playlist/playlist-apect-ratio-restriction-mode-data.service';

// registerLocaleData(localeEt);

// import { UploadService } from "./services/upload.service";
// import { UserPopupComponent } from './components/user-popup/user-popup.component';
// export function localeFactory(localeSettingsService: LocaleSettingsService) {
//     return localeSettingsService.locale;
// }

@NgModule({
    imports: [
        CommonModule, HttpClientModule, RouterModule, SharedModule,
        MatMomentDateModule
        // ToastModule.forRoot(),
    ],
    exports: [
        HttpClientModule, RouterModule, SharedModule
        // ToastModule,
        // UserPopupComponent,
    ],
    declarations: [
        // UserPopupComponent
    ],
    providers: [AuthDataService,
        AuthService, AuthGuardService, NotAuthGuardService,
        DeactivateGuardService,
        UploadApiService, ProcessApiService,
        FormTypeBuilder,
        RatingDataService, TagDataService, MediaDataService, MediaTypeDataService, DistributionChannelDataService,
        ApiAccessDataService, TemplateBaseDataService, TemplateInstanceDataService,
        MediaVersionDataService, ResourceDataService, UserDataService, ClientDataService, LocationDataService,
        ScreenDataService, AdSpaceDataService,
        EventDataService, CampaignDataService, ShowDataService, AuditoriumDataService,
        LanguageDataService, ScreenGroupDataService, TargetAudienceDataService, ProgramDataService,
        CollectionDataService, InboxDataService, TrashDataService, ChannelDataService,
        DeviceDataService, SpotDataService, DeviceChannelDataService,
        FloorMapDataService,
        ExtContentSourceDataService,
        SpotAppFilterRuleDataService,
        RelatedConfBaseDataService,
        ExtCommandDataService,
        StatusDataService,
        DeviceTypeDataService,
        ResourceMainTypeDataService,
        ResourceTypeDataService,
        ScreenGroupTypeDataService,
        GenreDataService, GenreTypeDataService,
        SpotTypeDataService,
        SpotRemoteTriggerTypeDataService,
        TimeRangeDataService, TimeRangeTypeDataService,
        DeviceAuditoriumRelationDataService,
        ProductDataService, ProductGroupDataService, ProductPriceDataService, ProductPriceGroupDataService, ProductGroupTypeDataService,
        SpotAppDataService,
        ClientResolver, UserResolver,
        HubService, DeviceHubService, ProcessApiJobHubService,
        BackgroundJobDataService,
        GlobalDialogService,
        HelperService,
        XhrUploadService,
        MediaVersionUploadService, ResourceUploadService, LocaleSettingsService,
        MatMomentDateModule,
        SpotAppEqLevelDataService,
        SpotAppFreeSpaceFillModeDataService,
        SpotAppInstanceDataModeDataService,
        SpotAppEqMethodDataService,
        SalesPointDataService,
        // SpotTriggerOffsetModeDataService,
        TimeZoneDataService,
        DevicePortalApiService,
        FileTypeDataService,
        PlaylistCalculationFrequencyDataService,
        TransitionModeDataService,
        TransitionDirectionDataService,
        ProductProductRelationTypeDataService,
        ResourceTypeIntentDataService,
        ProductPriceGroupTypeDataService,
        ProductPriceGroupTargetTypeDataService,
        SpotAppTypeDataService,
        TemplateInstanceProductPriceFetchingRuleDataService,
        TemplateInstanceProductGroupFetchingRuleDataService,
        CampaignTypeDataService,
        BookingDataService,
        PlaylistApectRatioRestrictionModeDataService,
        { provide: LOCALE_ID, useValue: 'et' },
        { provide: MAT_DATE_LOCALE, useValue: 'et' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        ApplicationInsightsService
        // {
        //     provide: LOCALE_ID,
        //     deps: [LocaleSettingsService],
        //     useFactory: localeFactory
        // }
    ]
})
export class CoreModule {

    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error(
                'CoreModule is already loaded. Import it in the AppModule only');
        }
    }
    // // config: UserServiceConfig
    // static forRoot(): ModuleWithProviders {
    //     return {
    //         ngModule: CoreModule,
    //         providers: [
    //             //{ provide: UserServiceConfig, useValue: config }
    //         ]
    //     };
    // }
}
