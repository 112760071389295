export enum ObjectType {
    UNKNOWN = 0,
    SCREEN = 1,
    DEVICE = 2,
    CHANNEL = 3,
    SCREEN_GROUP = 4,
    MEDIA_TYPE = 5,
    MEDIA = 6,
    TAG = 7,
    CAMPAIGN = 8,
    PROGRAM = 9,
    RATING = 10,
    MEDIA_VERSION = 11,
    EVENT = 12,
    EVENT_SHOW = 13,
    LANGUAGE = 14,
    TARGET_AUDIENCE = 15,
    LOCATION = 16,
    AUDITORIUM = 17,
    PROGRAM_SCHEDULE = 18,
    RESOURCE = 19,
    FILE = 20,
    RELATED_CONTENT = 21,
    RELATED_DATETIME = 22,
    RELATED_OBJECT = 23,
    SPOT_APP_FILTER_RULE = 24,
    EXT_CONTENT_SOURCE = 25,
    EXT_COMMAND = 26,
    RELATED_BOOLEAN = 27,
    GENRE = 28,
    DISTRIBUTION_CHANNEL = 29,
    TEMPLATE_BASE = 30,
    TEMPLATE_INSTANCE = 31,
    TEMPLATE_VARIABLE = 32,
    RELATED_CONF_BASE = 33,
    TIMERANGE = 34,
    TIMERANGE_RELATION = 35,
    PRODUCT = 36,
    PRODUCT_GROUP = 37,
    PRODUCT_PRICE = 38,
    PRODUCT_PRICE_GROUP = 39,
    RESOURCE_TYPE = 40,
    AD_SPACE = 41,
    SPOT = 42,
    CAMPAIGN_SCHEDULE = 43,
    USER = 44,
    SALESPOINT = 45,

    // USER = 90001, // not really in use form DB side
    STATUS = 90002, // not really in use form DB side
    SPOT_APP = 90003, // not really in use form DB side // TODO: change if Object Type created for spot and spot app
    API_ACCESS = 90004,
    COLLECTION = 90005,
    DEVICE_CHANNEL = 90006,
    FLOOR_MAP = 90007,
    HTML_CONTENT = 90008,
    HTML_CONTENT_TEMPLATE = 90009,
    SPOT_APP_FILTER = 90010
}

export enum RelatedObjectType {
    CONTENT = ObjectType.RELATED_CONTENT,
    DATETIME = ObjectType.RELATED_DATETIME,
    OBJECT = ObjectType.RELATED_OBJECT,
    BOOLEAN = ObjectType.RELATED_BOOLEAN,
}

export enum Status {
    Unknown = 0, Suspended = 5, Emergency = 6, Active = 10
}

export enum StatusSystem {
    Default = 0, Initialized = 1, Deleted = 2
}

export enum PublishStatus {
    UnPublished = 0, Published = 1
}

export enum PublishRequirementStatus {
    NeedsEditing = 0, ReadyForPublish = 1
}

export enum Resolutions {
    Unknown = 0, HD = 1, FullHD = 2, FourK = 3
}

//  Processing = -2, Uploading = -1,
// export enum MediaVersionStatusTech { Undefined = 20, UploadInit = 21,
// UploadStart = 22, Uploaded = 23, ProcessStart = 24, Processed = 25, UploadError = 30, ProcessError = 31 }
export enum MediaVersionStatusTechUpload {
    NotSet = 0, Undefined = 20, UploadInit = 21,
    Uploading = 22, Uploaded = 23, UploadError = 29
}
export enum MediaVersionStatusTechProcess {
    NotSet = 0, Undefined = 30, ProcessInit = 31,
    Processing = 32, Processed = 33, ProcessError = 39
}

export enum MediaVersionStatusFlow { Undefined = 40, AwaitingApproval = 41, Approved = 42 }

// export enum UploadStatus { Undefined, Uploading, UploadError, Uploaded, Processing, ProcessError, Processed }

export enum CollectionType {
    Unknown = 0, Inbox = 1, Archive = 2, Trash = 3
}

// export enum ProgramContentType { Unknown = 0, Media = 1 }

export enum ScheduleTargetType {
    Unknown = ObjectType.UNKNOWN,
    Screen = ObjectType.SCREEN,
    Device = ObjectType.DEVICE,
    Channel = ObjectType.CHANNEL,
    // TODO: don't support scheduling to Channel, Channel should only represent "holes" in device.
    // future, if user schedules to device or group then user needs to select what channel to use
    OutputGroup = ObjectType.SCREEN_GROUP
}

export enum SpotType { Generic = 0, ReusableDeprecated = 1, Interval = 2, Timed = 3 }

export enum SpotTriggerType { Unknown = 0, In = 1, Out = 2 }

// LinkedSpotContentStart = 3, LinkedSpotContentEnd = 6, TriggerStart = 7
export enum SpotTriggerAction {
    Unknown = 0, IN_PlayTurn = 1, IN_ShowStart = 2, OUT_ContentEnd = 4,
    OUT_ShowStart = 5, IN_RemoteTrigger = 7, IN_ShowEnd = 8, OUT_ShowEnd = 9, IN_Time = 10, OUT_Time = 11
}

export enum SpotAppSourceTargetType {
    Unknown = ObjectType.UNKNOWN,
    MediaType = ObjectType.MEDIA_TYPE, Media = ObjectType.MEDIA,
    Tag = ObjectType.TAG, Campaign = ObjectType.CAMPAIGN,
    Location = ObjectType.LOCATION,
    RelatedConfBase = ObjectType.RELATED_CONF_BASE,
    ProductGroup = ObjectType.PRODUCT_GROUP
}

export enum SpotAppFilterTargetType {
    Unknown = ObjectType.UNKNOWN,
    Tag = ObjectType.TAG,
    FilterRule = ObjectType.SPOT_APP_FILTER_RULE,
    Location = ObjectType.LOCATION,
    Auditorium = ObjectType.AUDITORIUM,
    DistributionChannel = ObjectType.DISTRIBUTION_CHANNEL,
    SalesPoint = ObjectType.SALESPOINT
}

export enum TemplateInstanceSourceTargetType {
    Unknown = ObjectType.UNKNOWN,
    RelatedConfBase = ObjectType.RELATED_CONF_BASE,
    Location = ObjectType.LOCATION,
    Auditorium = ObjectType.AUDITORIUM,
    DistributionChannel = ObjectType.DISTRIBUTION_CHANNEL,
}

export enum TemplateInstanceFilterTargetType {
    Unknown = ObjectType.UNKNOWN,
    RelatedConfBase = ObjectType.RELATED_CONF_BASE,
    Location = ObjectType.LOCATION,
    Auditorium = ObjectType.AUDITORIUM,
    DistributionChannel = ObjectType.DISTRIBUTION_CHANNEL,
}

export enum SpotAppType { Unknown = 0, Generic = 1, Media = 2, Schedule = 3, Product = 4, Event = 5 }
export enum SpotAppSubType { Unknown = 0, ScheduleDoor = 1 }

// , IdentedUse = 20
export enum ScreenGroupType {
    Unknown = 0, General = 10, Reciver = 11,
    Building = 100, Floor = 110, Area = 120, Position = 130
}

export enum DeviceType {
    Unknown = 0, SignageMonitor = 1, Kiosk = 2, Player = 3,
    PlayerMonitor = 4, Peripheral = 5, TX = 6, RX = 7, Tuner = 8, Container = 9
}

export enum ResourceStatusTechUpload { Unknown = 0, Undefined = 20, UploadInit = 21, Uploading = 22, Uploaded = 23, UploadError = 29 }
export enum ResourceStatusTechProcess { Undefined = 30, ProcessInit = 31, Processing = 32, Processed = 33, ProcessError = 39 }

export enum ResourceMainType { Undefined = 0, Generic = 1, Background = 2, Icon = 3, Imported = 4, Logo = 5, TemplateMedia = 6 }

export enum EventType { Undefined = 0, Group = 1, General = 2, Movie = 3 }

export enum PropertyIntentID {
    Undefined = 0,
    EventOriginalTitle = 1,
    GeneralTitle = 2,
    EventSynopsis = 3,
    EventGenreNames = 4,
    EventProductionYear = 5,
    EventLengthInMinutes = 6,
    EventLocalDistributorName = 7,
    EventGlobalDistributorName = 8,
    EventProductionCompanyNames = 9,
    EventCast = 10,
    EventDirectors = 11,
    EventRelease = 12,
    EventRating = 13,
    EventShowAuditorium = 14,
    EventShowStart = 15,
    EventShowStop = 16,
    EventShowPresentationMethod = 17,
    EventShowSpokenLanguage = 18,
    EventShowSubtitleLanguage1 = 19,
    EventShowSubtitleLanguage2 = 20,
    EventShowSynopsis = 21,
    EventShowEventSeriesNames = 22,
    EventShowSeatavailabilityTotal = 23,
    EventShowSeatavailabilityAvailable = 24,
    RatingName = 26,
    RatingCode = 27,
    RatingImageUrl = 28,
    AuditoriumName = 29,
    AuditoriumLocation = 30,
    LocationName = 31,
    LanguageName = 32,
    LanguageNameInLang = 33,
    LanguageIsoCode = 34,
    LanguageIsoTwoLetterCode = 35,
    CampaignTitle = 36,
    EventExternalContentSource = 37,
    EventOutputGroups = 38,
    EventOutputs = 39,
    EventDisableCommandExecution = 40,
    InternalComment = 41,
    Excerpt = 42,
    Genre = 43,
    DistributionChannel = 44,
    SwitchStart = 45,
    ShortInfo = 46,
    HideTitle = 47
}

export enum DeviceDeviceRelationType {
    Unknown = 0,
    Tx_Tuner = 1,
    Rx_SignageMonitor = 2,
    Master_Slave = 3,
    Content_Source = 4
}

export enum InputElementType {
    Default = 0,
    Text = 1,
    Textarea = 2,
    DateTime = 3,
    Date = 4,
    Time = 5,
    Number = 6,
    Select = 7
}

export enum RelatedConfBaseType {
    Unknown = 0,
    Generic = 1,
    TvEvent = 2,
    TvEventShow = 3,
    MovieEvent = 4,
    MovieEventShow = 5,
    RoomEvent = 6,
    RoomEventShow = 7
}

export enum ExtCommandState {
    Default = 0,
    Transfer = 1,
    Received = 2,
    Remove = 3,
    Removed = 4,
    Executed = 5,
    Error = 500
}

export enum ExtCommandType {
    Unknown = 0,
    MatrixSet = 1,
}

export enum GenreType {
    Default = 0,
    Movie = 1,
    Sport = 2
}

export enum TimeRangeType {
    Generic = 0,
    MediaRating = 1,
    OutputActivity = 2,
    ProgramValidity = 3
}

export enum DeviceAuditoriumType {
    Unknown = 0,
    Generic = 1,
    Door = 2
}

export enum ProductGroupType {
    Unknown = 0,
    Generic = 1,
    Campaign = 2,
    Top = 3,
    Menu = 4,
    Integration = 5
}

export enum RemovalTarget {
    Unknown = 0,
    Start = 1,
    End = 2
}

export enum InclusionTarget {
    Unknown = 0,
    Start = 1,
    End = 2
}

export enum InclusionOffsetMode {
    TargetOffset = 0,
    BusinessDayStartOffset = 1
}

export enum RemovalOverlapMode {
    Unknown = 0,
    CurrentRemoval = 1,
    NextStart = 2
}

export enum SpotAppEqMethod {
    None = 0,
    Count = 1,
    Time = 2
}

export enum SpotAppEqLevel {
    Unknown = 0,
    MediaVersion = 1,
    Media = 2,
    Campaign = 3,

    
}

// export enum ProgramType {
//     Unknown = 0,
//     Fixed = 1,
//     Equalized = 2
// }

export enum SpotAppSourcePerRangePlayTarget {
    Unknown = 0,
    PerPool = 1, // Per Spot App Source Pool
    PerItem = 2
}

export enum SpotAppPerRangePlayTarget {
    Unknown = 0,
    PerPool = 1, // Per Spot App Pool
    PerItem = 2
}

export enum FileType { Unknown = 0, Original = 1, Converted = 2, Thumbnail = 3, Remote = 4 }
export enum FileSubType {
    Unknown = 0,
    RegularOriginal = 100, RegularHD = 110, RegularFHD = 120, Regular4K = 130,
    TransposeOriginal = 200, TransposeHD = 210, TransposeFHD = 220, Transpose4K = 230,
    LandscapeQHD = 1000, PortraitB1 = 1010, PortraitPosterLarge = 1050
}

export const INIT_URL_PLACEHOLDER = '_init_';
export const NEW_URL_PLACEHOLDER = '_new_';

export type DataServiceType = 'default'; // | 'html-content-api';

export enum DeviceRotation {
    Default = 0,
    Clockwise90 = 90,
    Clockwise180 = 180,
    Clockwise270 = 270
}


export enum PlaylistCalculationFrequency {
    OnceInHour = 0,
    TwiceInHour = 1,
    OnceInTwoHours = 2,
    OnceInRange = 3,
    TwiceInRange = 4,

}

export enum ApectRatioRestrictionMode {
    Default = 0,
    None = 1,
    Loose = 2,
    Approximate20 = 3,
    Approximate1 = 4,
    Strict = 5
}

export enum PlayerTimeMatchMode { 
    Unknown = 0,
    Strict = 1, 
    Loose = 2
}

export enum SpotAppInstanceDataMode {
    Default = 0, PerItem = 1, PerPool = 2
}


export enum TransitionMode {
    None = 0, Push = 1, Slide = 2, Fade = 3
}

export enum TransitionDirection {
    None = 0, LeftToRight = 1, RightToLeft = 2, TopToBottom = 3, BottomToTop = 4
}

export enum ProductProductRelationType {
    Subitem = 0, Modifier = 1
}

export enum PlayerScheme {
    Http = 1,
    Https = 2,
}

export enum ResourceTypeIntent {
    Undefined = 0, Main = 1, Background = 2, Detail = 3, Logo = 4, MainBackup = 5
}

export enum ProductPriceGroupType {
    Undefined = 0, Loyalty = 1, Quantity = 2, Size = 3, Combo = 4, ScreeningMethod = 5, Ticket = 6, SalesPoint = 7, Discount = 8
}

export enum ProductPriceGroupTargetType {
    Unknown = ObjectType.UNKNOWN,
    SalesPoint = ObjectType.SALESPOINT
}

export enum TemplateInstanceProductPriceFetchingRule {
    NoPrices = 0,
    Salespoints = 1,
    AllPrices = 2
}

export enum TemplateInstanceProductGroupFetchingRule {
    NoGroups = 0,
    PoolingGroups = 1,
    AllGroups = 2
}

export enum CampaignType {
    Unknown = 0,
    Generic = 1,
    Booking = 2,
    // Event = 3,
    // Product = 4
}

export enum SpotAppFreeSpaceFillMode {
    None = 0,
    AllowFillByWantedIntervalInHour = 1
}

export enum RemoteTriggerType {
    Unknown = 0,
    InstanceScreenTimeRequest = 1,
    ProximityDetected = 2,
}